import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  CardImg,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";
import AboutUsHeader from "components/Headers/AboutUsHeader.js";
import SectionPackagePentest from "views/sections-packages/SectionPackagePentest.js"
import FooterBlack from "components/Footers/FooterBlack";

import CyberSafeLogo from "assets/img/partners/Partner-CyberSafe-Positif-RVB-big-transparent.png";
import Diagnostic from "assets/img/cyber-safe/DIAGNOSTIC.png";
import { BiAnalyse } from "@react-icons/all-files/bi/BiAnalyse";
import { FcProcess } from "@react-icons/all-files/fc/FcProcess";
import { AiOutlineAudit } from "@react-icons/all-files/ai/AiOutlineAudit";
import { RiFindReplaceLine } from "@react-icons/all-files/ri/RiFindReplaceLine";
import SectionContactUs from "./sections-sections/SectionContactUs";

import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

function CyberSafe() {

  const handleMouseEnter = (e) => {


    e.target.style.animation = "uil-reload-css"
    e.target.style.animationDuration = "2s"
    e.target.style.animationTimingFunction = "ease"
    e.target.style.animationDelay = "01s"
    e.target.style.animationIterationCount = 1
  }
  const handleMouseOut = (e) => {
    e.target.style.animation = "none"
  }

  const { t } = useTranslation();

  return (
    <>
      <ColorNavbar />
      <AboutUsHeader />
      <div className="wrapper">
        <div className="main">
          <div className="section section-gray">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto text-center title" md="8">
                  <h1>Cyber Safe</h1>
                  <Col className="ml-auto mr-auto" md="10">
                    <div className="text-center">
                      <h6 className="title-uppercase">Le Label Suisse de Cybersécurité</h6>
                    </div>
                  </Col>

                  <img src={CyberSafeLogo} width='200px' alt="Cyber Safe"></img>

                </Col>
              </Row>
              <Row>

                <Col className="ml-auto mr-auto description" md="10">
                  <p>L’association Suisse pour le Label de Cybersécurité a développé le Label cyber-safe.ch pour permettre aux PME, communes et autres administrations d’atteindre un niveau de sécurité informatique défini comme acceptable par un ensemble de partenaires publics et privés. <br />La méthode d’évaluation originale alliant outil en ligne, questionnaires, tests d’hameçonnage et interventions humaines, le label offre un outil d’aide à la décision et de connaissance fine des cyberrisques en matière d’infrastructure, d’organisation et de compétences humaines. Il permet ainsi aux organisations candidates d’implémenter les mesures préventives pour sécuriser leurs données et conserver ainsi son niveau opérationel d’activités.
                    <br />Les outils de diagnostic de cyber-safe.ch, comme les tests de phishing, le scan sur site du réseau informatique et la revue des exigences en matière de cybers-sécurité sont conçus avec une approche pragmatique qui pondère niveau de risque avec l’inventaire complet de l’estimation de valeur de données.</p>

                  <p>Maîtrisez votre informatique</p>
                  <ul>
                    <li><p>Votre sécurité informatique maîtrisée grâce à un Label reconnu.</p></li>
                    <li><p>Vous êtes préparés en cas de cyberincident.</p></li>
                    <li><p>Vous renforcez la relation de confiance avec vos partenaires.</p></li>
                  </ul>

                  <p>Pour améliorer votre sécurité numérique et votre résilience</p>
                  <ul>
                    <li><p>Votre état des lieux et vos priorités, rapidement.</p></li>
                    <li><p>En 1 mois, vous obtenez un point de vue neutre et indépendant sur :</p>
                      <ul>
                        <li><p>	Votre profil de risque.</p></li>
                        <li><p>Votre feuille de route avec priorisation.</p></li>
                      </ul>
                    </li>
                  </ul>
                  <Container>
                    <Row>
                      <Col className="ml-auto mr-auto text-center" md="8">
                        <h3 className="title">Le processus cyber-safe.ch</h3>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="4">
                        <div className="info">
                          <div className="icon icon-danger">

                            <BiAnalyse alt="Diagnostic" />

                          </div>
                          <div className="description">
                            <h4 className="info-title">Diagnostic</h4>
                            <p className="description">
                              Tester vos systèmes informatiques, vos collaborateurs et votre gouvernance afin d’identifier les failles et vulnérabilités pour dresser un état des lieux de votre cybersécurité.

                            </p>
                          </div>
                        </div>
                      </Col>
                      <Col md="4">
                        <div className="info">
                          <div className="icon icon-danger">
                            <RiFindReplaceLine alt="Aide à la décision" />
                          </div>
                          <div className="description">
                            <h4 className="info-title">Aide à la décision</h4>
                            <p className="description">
                              Etablir une feuille de route pour la conduite opérationnelle (gestion des priorités selon les risques, les impacts financiers et le secteur d’activité). Sur la base de l'état des lieux, une liste des actions prioritaires pour améliorer votre cybersécurité.
                            </p>
                          </div>
                        </div>
                      </Col>
                      <Col md="4">
                        <div className="info">
                          <div className="icon icon-danger">
                            <AiOutlineAudit alt="Cyber Audit" />
                          </div>
                          <div className="description">
                            <h4 className="info-title">Cyber Audit</h4>
                            <p className="description">
                              Auditer et vérifier que les mesures corrective ont été appliquées. Nous contrôlons la conformité de votre cybersécurité aux exigences du Label.
                            </p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                  <br /><br />
                  <Row className="space-top ">
                    <Col md="12">
                      <center>
                        <Button
                          className="btn-round btn-danger center"
                          onMouseEnter={handleMouseEnter}
                          onMouseOut={handleMouseOut}
                          onClick={(e) => {
                            e.preventDefault();
                            document.getElementById("contact-us").scrollIntoView({
                              behavior: "smooth",
                              block: "start",
                              inline: "nearest",
                            });
                          }}
                        >
                          {t('cta.call_to_action_1_button')}
                        </Button>
                      </center>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
      <SectionContactUs />
      <FooterBlack />
    </>
  );
}

export default CyberSafe;
