
import React from "react";

// reactstrap components
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    Row,
    Col,
    CardImg,
    Carousel,
    CarouselItem,
    CarouselIndicators,
} from "reactstrap";

import { useTranslation } from 'react-i18next';



import CyberSafeLogo from "assets/img/partners/Partner-CyberSafe-Positif-RVB-big-transparent.png";
import BaloiseLogo from "assets/img/partners/baloise.svg"


function SectionPartners() {

    const { t } = useTranslation();

    // core components
const items = [
    {
        src:
            CyberSafeLogo,
        content: (
            <CardBody>
                <h5 className="card-description">
                    {t("partners.partner_cybersafe_desc")}
                </h5>
                <CardFooter>

                    <Button
                        className="btn-round btn-danger"
                        color="danger"
                        href="/cyber-safe">{t("partners.partner_discover_more")}</Button>
                </CardFooter>
            </CardBody>
        ),
        altText: "Cyber Safe",
        caption: "Cyber Safe",
    },
    {
        src:
            BaloiseLogo,
        content: (
            <CardBody>
                <h5 className="card-description">
                    {t("partners.partner_baloise_desc")}
                </h5>
                <CardFooter>
                <Button
                        className="btn-round btn-danger"
                        color="danger"
                        onClick={(e) => {
                            e.preventDefault();
                            document.getElementById("contact-us").scrollIntoView({
                              behavior: "smooth",
                              block: "start",
                              inline: "nearest",
                            });
                          }}>{t("partners.partner_discover_more")}</Button>
                </CardFooter>
            </CardBody>
        ),
        altText: "Baloise Assurances",
        caption: "Baloise Assurances",
    }
]

    // carousel - TESTIMONIALS 2
    const [activeIndex, setActiveIndex] = React.useState(0);
    const [animating, setAnimating] = React.useState(false);
    const onExiting = () => {
        setAnimating(true);
    };
    const onExited = () => {
        setAnimating(false);
    };
    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };
    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };
    const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
    };
    return (
        <>
            <div className="section section-testimonials cd-section" id="partners">
                <Row>
                    <Col className="ml-auto mr-auto text-center" md="8">
                        <h2 className="title">Partenaires</h2>
                    </Col>
                </Row>
                <Row>
                    <Col className="ml-auto mr-auto" md="6">
                        <div className="page-carousel">
                            <Carousel
                                activeIndex={activeIndex}
                                next={next}
                                previous={previous}
                            >
                                <CarouselIndicators
                                    items={items}
                                    activeIndex={activeIndex}
                                    onClickHandler={goToIndex}
                                />
                                {items.map((item) => {
                                    return (
                                        <CarouselItem
                                            onExiting={onExiting}
                                            onExited={onExited}
                                            key={item.src}
                                        >
                                            <Card className="card-testimonial card-plain">
                                                <CardBody>


                                                    <CardImg alt={item.altText} style={{ width: "100%", maxWidth: "220px", height: "120px" }} src={item.src} />

                                                    {item.content}
                                                </CardBody>
                                            </Card>
                                        </CarouselItem>
                                    );
                                })}
                                <a
                                    className="left carousel-control carousel-control-prev"
                                    data-slide="prev"
                                    href="#"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        previous();
                                    }}
                                    role="button"
                                >
                                    <span className="fa fa-angle-left" />
                                    <span className="sr-only">Previous</span>
                                </a>
                                <a
                                    className="right carousel-control carousel-control-next"
                                    data-slide="next"
                                    href="#"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        next();
                                    }}
                                    role="button"
                                >
                                    <span className="fa fa-angle-right" />
                                    <span className="sr-only">Next</span>
                                </a>
                            </Carousel>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default SectionPartners;