/*!

=========================================================
* Paper Kit PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-pro-react
* Copyright 2021 Creative Tim (http://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";

// styles
import "bootstrap/scss/bootstrap.scss";
import "assets/scss/paper-kit.scss";
import "assets/demo/demo.css";
import "assets/demo/react-demo.css";
// pages
import Index from "views/Index.js";
import AboutUs from "views/AboutUs.js";
import Services from "views/Services";
import ServicesDetails from "views/ServicesDetails";
import Error404 from "views/examples/Error404";
import Politique from "views/Politique";

import CyberSafe from "views/CyberSafe";

import './i18n';

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path={["/home", "/"]} exact={true} render={(props) => <Index {...props} />} hrefLang={localStorage.getItem('lang')}/>
      <Route path="/services" exact={true} render={(props) => <Services {...props} />} hrefLang={localStorage.getItem('lang')}/> 
      <Route path="/services/details" exact={true} render={(props) => <ServicesDetails {...props} />} hrefLang={localStorage.getItem('lang')} /> 
      <Route path="/about-us" exact={true} render={(props) => <AboutUs {...props} />} hrefLang={localStorage.getItem('lang')}/>
      <Route path="/cyber-safe" exact={true} render={(props) => <CyberSafe {...props} />} hrefLang={localStorage.getItem('lang')}/>
      <Route path="/cg" exact={true} render={(props) => <Politique {...props} />} hrefLang={localStorage.getItem('lang')}/>

      {/*<Route path="/blog" exact={true} render={(props) => <BlogPosts {...props} />} hrefLang={localStorage.getItem('lang')}/>
      <Route path="/blog/post/log4j" component={Log4j} />
      <Route path="/blog/post/postgresql_hardening" component={PostgreSQL} />
      <Route path="/blog/post/admin_management" component={DomainAdministratorManagement} />
      
      {blogsData.data.map(blogData => (
        <Route path={blogData.slug} key={blogData['id']}  render={(props) => <Post {...props} data={blogData} />} />
      ))}*/}
      {/*<Route path="/whoops" exact={true} render={(props) => <Error404 {...props} />} hrefLang={localStorage.getItem('lang')}/>*/}
      {/*<Route path="/politique" exact={true} render={(props) => <Politique {...props} />} hrefLang={localStorage.getItem('lang')}/>*/}
      <Redirect to="/home" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
