import React, {lazy, Suspense} from "react";

// reactstrap components
// import {
// } from "reactstrap";

// core components
/*import ColorNavbar from "components/Navbars/ColorNavbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import FooterBlack from "components/Footers/FooterBlack.js";
// sections for this page
import SectionServices from "./sections-sections/SectionServices.js";
import SectionComponents from "./presentation-sections/SectionComponents.js";
import SectionWhyUs from "./presentation-sections/SectionWhyUs.js";
import SectionContactUs from "./sections-sections/SectionContactUs.js";
//import SectionPackagePentest from "./sections-packages/SectionPackagePentest.js";

import SectionCTA1 from "./presentation-sections/SectionCTA1.js";
import SectionCertifications from "./sections-sections/SectionCertifications.js";
import SectionPartners from "./sections-sections/SectionPartners.js";*/
import { useTranslation } from 'react-i18next';



//import SectionCTA1 from "./presentation-sections/SectionCTA1.js";


const ColorNavbar = lazy(() => import("components/Navbars/ColorNavbar.js"));
const LandingPageHeader = lazy(() => import("components/Headers/LandingPageHeader.js"));
const FooterBlack = lazy(() => import( "components/Footers/FooterBlack.js"));
// sections for this page
const SectionServices = lazy(() => import("./sections-sections/SectionServices.js"));
const SectionComponents = lazy(() => import("./presentation-sections/SectionComponents.js"));
const SectionWhyUs = lazy(() => import( "./presentation-sections/SectionWhyUs.js"));
const SectionContactUs = lazy(() => import( "./sections-sections/SectionContactUs.js"));
//import SectionPackagePentest from "./sections-packages/Sec  tionPackagePentest.js";
const SectionCTA1 = lazy(() => import( "./presentation-sections/SectionCTA1.js"));

const SectionCertifications = lazy(() => import( "./sections-sections/SectionCertifications.js"));
const  SectionPartners = lazy(() => import("./sections-sections/SectionPartners.js"));


function Index() {
  const { i18n } = useTranslation();
  if(!localStorage.getItem('lang'))
    localStorage.setItem('lang', "fr");
  else {
    i18n.changeLanguage(localStorage.getItem('lang'));
  }

  const renderLoader = () => <p>Loading</p>;
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("index-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
    };
  });
  return (
    <Suspense fallback={renderLoader()}>

        <ColorNavbar />
        <LandingPageHeader />
        <SectionComponents />
        <SectionServices />
        <SectionCertifications />
        <SectionWhyUs />
        <SectionPartners />
        <SectionCTA1 />
        {/*<SectionPackagePentest />*/}
        <SectionContactUs />
        <FooterBlack />
      </Suspense>
    
  );
  {/*<Suspense fallback={renderLoader()}>
      <ColorNavbar />
      <LandingPageHeader />
      <SectionComponents />
      <SectionServices />
      {/*<SectionPackagePentest />*/}
      {/*<SectionSummary />
      <SectionWhyUs />
      <SectionCTA1 />
      <SectionContactUs />
      <FooterBlack />*/}
}

export default Index;