
import React from "react";

// reactstrap components
import {
    CardBody,
    Container,
    Card,
    CardHeader,
    Button,
    Collapse,
    Row,
    Col,
} from "reactstrap";

import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

// core components

function SectionSolution() {
    const { t } = useTranslation();

    const [collapses, setCollapses] = React.useState([]);
    const changeCollapse = collapse => {
        if (collapses.includes(collapse)) {
            setCollapses(collapses.filter(prop => prop !== collapse));
        } else {
            setCollapses([...collapses, collapse]);
        }
    };
    return (
        <>
            <div className="section cd-section section-gray md-auto mr-auto" id="solutions">
                <Container className="md-auto mr-auto " >
                    <Row>
                        <Col className="text-center">
                            <h2 className="title">{t('services.services_solutions')}</h2>
                            <p className="text-center description" md="2">
                                {t("services.services_solutions_resume")}
                            </p>
                        </Col>
                    </Row>
                    <Row style={{}}>
                        <Col className="mr-auto" >
                            <div className="testimonials-2 section-testimonials">
                                <Container>
                                    <Row>
                                        <Col className="hidden-mobile mr-auto ml-auto">
                                            <div className="testimonials-people">
                                                <img
                                                    alt="OSQuery"
                                                    className="left-first-person add-animation bg-white"
                                                    src={require("assets/img/osquery.png") }
                                                    style={{objectFit: "contain"}}
                                                />
                                                <img
                                                    alt="Docker"
                                                    className="left-second-person add-animation bg-white"
                                                    src={require("assets/img/webp/docker.webp") }
                                                    style={{objectFit: "contain"}}
                                                />
                                                <img
                                                    alt="SELinux"
                                                    className="left-third-person add-animation bg-white"
                                                    src={require("assets/img/selinux.png") }
                                                    style={{objectFit: "contain"}}
                                                />
                                                <img
                                                    alt="ELK"
                                                    className="left-fourth-person add-animation bg-white"
                                                    src={require("assets/img/ELK_round.png") }
                                                    style={{objectFit: "contain"}}
                                                />
                                                <img
                                                    alt="pfSense"
                                                    className="left-fifth-person add-animation bg-white"
                                                    src={require("assets/img/pfsense_round.png") }
                                                    style={{objectFit: "contain"}}
                                                />
                                                <img
                                                    alt="Linux"
                                                    className="left-sixth-person add-animation nc-minimal-down bg-white"
                                                    src={require("assets/img/webp/Linux.webp") }
                                                    style={{objectFit: "contain"}}
                                                />
                                            </div>
                                        </Col>
                                        <Col className="ml-auto mr-auto " md="8" >
                                            <div className="page-carousel" >
                                                <Col className="ml-auto mr-auto text-center"  >
                                                    <div id="acordeon" >
                                                        <div aria-multiselectable={true} id="accordion" role="tablist" >
                                                            <Card className="no-transition">
                                                                <CardHeader className="card-collapse" id="headingOne" role="tab">
                                                                    <h5 className="mb-0 panel-title">
                                                                        <a
                                                                            aria-expanded={collapses.includes(1)}
                                                                            className="collapsed"
                                                                            data-parent="#accordion"
                                                                            id="collapseOne"
                                                                            href="/#"
                                                                            onClick={e => {
                                                                                e.preventDefault();
                                                                                changeCollapse(1);
                                                                            }}
                                                                        >
                                                                             {t("services.services_solutions_resume_title_1")}{" "}
                                                                            <i className="nc-icon nc-minimal-down" />
                                                                        </a>
                                                                    </h5>
                                                                </CardHeader>
                                                                <Collapse isOpen={collapses.includes(1)}>
                                                                    <CardBody >
                                                                        <div className="ml-auto mr-auto text-center col-sm-9">
                                                                            <p className="text-center description" md="2">
                                                                                {t("services.services_solutions_resume_desc_1")}
                                                                            </p>
                                                                        </div>
                                                                    </CardBody>
                                                                </Collapse>
                                                                <CardHeader className="card-collapse" id="headingTwo" role="tab">
                                                                    <h5 className="mb-0 panel-title description">
                                                                        <a
                                                                            aria-controls="collapseTwo"
                                                                            aria-expanded={collapses.includes(2)}
                                                                            className="collapsed"
                                                                            data-parent="#accordion"
                                                                            href="/#"
                                                                            id="collapseTwo"
                                                                            onClick={e => {
                                                                                e.preventDefault();
                                                                                changeCollapse(2);
                                                                            }}
                                                                        >
                                                                            {t("services.services_solutions_resume_title_2")}{" "}
                                                                            <i className="nc-icon nc-minimal-down" />
                                                                        </a>
                                                                    </h5>
                                                                </CardHeader>
                                                                <Collapse isOpen={collapses.includes(2)}>
                                                                    <CardBody>
                                                                        <div className="ml-auto mr-auto text-center col-sm-9 description">
                                                                            <p className="text-center description" md="2">
                                                                                {t("services.services_solutions_resume_desc_2")}{" "}
                                                                            </p>
                                                                        </div>
                                                                    </CardBody>
                                                                </Collapse>
                                                                <CardHeader
                                                                    className="card-collapse"
                                                                    id="headingThree"
                                                                    role="tab"
                                                                >
                                                                    <h5 className="mb-0 panel-title description">
                                                                        <a
                                                                            aria-controls="collapseThree"
                                                                            aria-expanded={collapses.includes(3)}
                                                                            className="collapsed"
                                                                            data-parent="#accordion"
                                                                            href="/#"
                                                                            id="collapseThree"
                                                                            onClick={e => {
                                                                                e.preventDefault();
                                                                                changeCollapse(3);
                                                                            }}
                                                                        >
                                                                                {t("services.services_solutions_resume_title_3")}{" "}
                                                                            <i className="nc-icon nc-minimal-down" />
                                                                        </a>
                                                                    </h5>
                                                                </CardHeader>
                                                                <Collapse isOpen={collapses.includes(3)}>
                                                                    <CardBody>
                                                                    <div className="ml-auto mr-auto text-center col-sm-9">
                                                                        <p className="text-center description" md="2">
                                                                            {t("services.services_solutions_resume_desc_3")}{" "}
                                                                        </p>
                                                                    </div>
                                                                         
                                                                    </CardBody>
                                                                </Collapse>
                                                               
                                                                
                                                            </Card>
                                                                                                     
                                                        </div>
                                                    </div>
                                                    
                                                </Col>
                                            </div>
                                        </Col>
                                        <Col className="ml-auto md-auto">
                                            <div className="testimonials-people add-animation">
                                                <img
                                                    alt="React"
                                                    className="right-first-person add-animation  bg-white"
                                                    src={require("assets/img/React.png") }
                                                    style={{objectFit: "contain"}}
                                                    
                                                />
                                                <img
                                                    alt="Python"
                                                    className="right-second-person add-animation bg-white"
                                                    src={require("assets/img/python.png") }
                                                    style={{objectFit: "contain"}}
                                                />
                                                <img
                                                    alt="Android"
                                                    className="right-third-person add-animation bg-white"
                                                    src={require("assets/img/Android.png") }
                                                    style={{objectFit: "contain"}}
                                                />
                                                <img
                                                    alt="PostgreSQL"
                                                    className="right-fourth-person add-animation bg-white "
                                                    src={require("assets/img/Postgresql_elephant.png") }
                                                    style={{objectFit: "contain"}}
                                                />
                                                <img
                                                    alt="Bash"
                                                    className="right-fifth-person add-animation bg-white"
                                                    src={require("assets/img/bash.png") }
                                                    style={{objectFit: "contain"}}
                                                />
                                                <img
                                                    alt="Suricata"
                                                    className="right-sixth-person add-animation bg-white"
                                                    src={require("assets/img/webp/suricata.webp") }
                                                    style={{objectFit: "contain"}}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                                <div className="text-center" md="9" style={{position:"relative"}} >
                                                                        <Button
                                                                        tag={Link}
                                                                        className="btn-round btn-center"
                                                                        color="danger"
                                                                        to={{
                                                                        pathname:"/services/details",
                                                                        hash: "#solution",
                                                                        state: {
                                                                            service: "solution", 
                                                                        }
                                                                        }}
                                                                    >
                                                                {t('banner.banner_button_1')}
                                                            </Button>
                                                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default SectionSolution;
