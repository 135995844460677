
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components

function SectionAudit() {
   
    return (
        <>
        {/* ********* PRICING 4 ********* */}
        <div className="pricing-4 section-pricing section-dark" id="pricing">
            <Container >
                <Row>
                <Col className="ml-auto mr-auto text-center" md="6">
                    <h2 className="title">Packages</h2>
                    <h5 className="description">
                        
                    </h5>
                </Col>
                </Row>
                <div className="space-top" />
                <Row className="d-flex h-100" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Col md="3">
                    <Card className="card-pricing">
                    <CardBody>
                        <h6 className="card-category text-danger">Scan de vulnérabilités</h6>
                        <CardTitle tag="h3">CHF 990.-</CardTitle>
                        <ul >
                            <li>
                            <i className="fa fa-check mr-1" />
                                Scan de vulnerabilités web ou infrastructure
                            </li>
                            <li>
                                <i className="fa fa-check mr-1" />
                                Reporting offert<br />
                            </li>
                        </ul>
                        <Button
                        className="btn-round"
                        color="danger"
                        onClick={(e) => {
                            e.preventDefault();
                            document.getElementById("contact-us").scrollIntoView({
                              behavior: "smooth",
                              block: "start",
                              inline: "nearest",
                            });
                          }}
                        outline>
                            Nous contacter
                        </Button>
                    </CardBody>
                    </Card>
                </Col>
                <Col md="3">
                    <Card className="card-pricing">
                    <CardBody>
                        <h6 className="card-category text-danger">
                            Bug bounty
                        </h6>
                        <CardTitle tag="h3">Dès CHF 1.-</CardTitle>
                        <ul>
                        <li>
                            <i className="fa fa-check mr-1" />
                            Tarif selon le nombre de failles découvertes
                        </li>
                        <li>
                            <i className="fa fa-check mr-1" />
                            Reporting offert
                        </li>
                        </ul>
                        <Button
                        className="btn-round"
                        color="danger"
                        onClick={(e) => {
                            e.preventDefault();
                            document.getElementById("contact-us").scrollIntoView({
                              behavior: "smooth",
                              block: "start",
                              inline: "nearest",
                            });
                          }}
                        outline>
                        Commander
                        </Button>
                    </CardBody>
                    </Card>
                </Col>
                <Col md="3">
                    <Card className="card-pricing">
                    <CardBody>
                        <h6 className="card-category text-danger">
                            Sensibilation
                        </h6>
                        <CardTitle tag="h3">Dès 600.-</CardTitle>
                        <ul>
                        <li>
                            <i className="fa fa-check mr-1" />
                            Présentation des techniques utilisés par les attaquants
                        </li>
                        <li>
                            <i className="fa fa-check mr-1" />
                             Réponses à vos questions
                        </li>
                        </ul>
                        <Button
                        className="btn-round"
                        color="danger"
                        onClick={(e) => {
                            e.preventDefault();
                            document.getElementById("contact-us").scrollIntoView({
                              behavior: "smooth",
                              block: "start",
                              inline: "nearest",
                            });
                          }}
                        outline>
                        Commander
                        </Button>
                    </CardBody>
                    </Card>
                </Col>
                </Row>
            </Container>
            </div>
            {/* ********* END PRICING 4 ********* */}
    </>
  );
}

export default SectionAudit;
