import React, { lazy, Suspense } from "react";

// reactstrap components
import {
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";

import {
  Grid
} from '@material-ui/core/'

import { useTranslation } from 'react-i18next';

import SectionPartners from "./sections-sections/SectionPartners";
import SectionCertifications from "./sections-sections/SectionCertifications";


// core components
const ColorNavbar = lazy(() => import("components/Navbars/ColorNavbar.js"));
const AboutUsHeader = lazy(() => import("components/Headers/AboutUsHeader.js"));
const FooterBlack = lazy(() => import("components/Footers/FooterBlack.js"));
const SectionContactUs = lazy(() => import("./sections-sections/SectionContactUs"));
const SectionCTA2 = lazy(() => import("./presentation-sections/SectionCTA2"));



function AboutUs() {
  const renderLoader = () => <p>Loading</p>;

  const { t, i18n } = useTranslation();
  if (!localStorage.getItem('lang'))
    localStorage.setItem('lang', "fr");
  else {
    i18n.changeLanguage(localStorage.getItem('lang'));
  }

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("about-us");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    var href = window.location.href.substring(
      window.location.href.lastIndexOf("#/") + 2
    );
    var hrefId = href.substring(href.lastIndexOf("#") + 1);
    if (href.lastIndexOf("#") > 0) {
      document.getElementById(hrefId).scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
    return function cleanup() {
      document.body.classList.remove("about-us");
    };
  });
  return (
    <Suspense fallback={renderLoader()}>
      <ColorNavbar />
      <AboutUsHeader />
      <div className="main">
        <div className="section">
          <Row className="space-top">
            <Col sm={{ size: 'auto', offset: 2 }} className="mr-4" lg="4" md="8" id="about">
              <Container className="basic-container">
                <h4 className="title-uppercase">{t('about.about_us_title')}</h4>
                <br />
                <p className="description">
                  {t('about.about_us_desc')}
                </p>
              </Container>
              <Container className="space-top" md="8">
                <Card data-color="blue">
                  <CardBody>
                    <strong>
                      <div className="author">

                        <span>{t('about.about_page_citation_source_div1')}</span>

                      </div>
                      <span className="category-social pull-right">
                        <i className="fa fa-quote-right" />
                      </span>
                      <div className="clearfix" />
                      <p className="card-description">
                        <strong>
                          {t('about.about_page_citation_div1')}
                        </strong>
                      </p>
                    </strong>
                  </CardBody>
                </Card>

              </Container>
            </Col>
            <Col className="text-center space-top" lg="4" md="5">
              <div className="image-container space-top ">
                <img
                  alt="Cybersecurity experts"
                  src={require("assets/img/webp/TrustHandShake.webp")}
                  height="auto"
                  width="80%"
                  style={{ objectFit: "fit", maxHeight: "100%", maxWidth: "100%" }}
                />
              </div>
            </Col>
          </Row>

        </div>
        <div className="space-top section section-nude" id="values" >

          <Row>

            <Col className="ml-auto mr-auto text-center" md="10" >
              <h2 className="title">{t('about.about_page_title_val')}</h2>
              {/*<p className="description">
                  {t('about.about_page_subtitle_val')}
                </p>*/}
              <br />
            </Col>
          </Row>
          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="center"
            alignItems="stretch"
          >
            <Grid item xs={8} sm={4} md={2}>
              <div className="card-big-shadow">
                <Card
                  className="card-just-text"
                  data-background="color"
                  data-color="blue"
                  data-radius="none"


                >
                  <CardBody style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                    <CardTitle tag="h4">
                      {t('about.about_page_val_1_title')}
                    </CardTitle>

                    <p className="card-description">
                      <strong>
                        {t('about.about_page_val_1_desc')}
                      </strong>
                    </p>

                  </CardBody>
                </Card>
                {/* end card */}
              </div>
            </Grid>
            <Grid item xs={8} sm={4} md={2}>
              <div className="card-big-shadow">
                <Card
                  className="card-just-text"
                  data-background="color"
                  data-color="green"
                  data-radius="none"
                  style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}
                >
                  <CardBody style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                    <CardTitle tag="h4">
                      {t('about.about_page_val_2_title')}
                    </CardTitle>

                    <p className="card-description">
                      <strong>
                        {t('about.about_page_val_2_desc')}
                      </strong>
                    </p>

                  </CardBody>
                </Card>
                {/* end card */}
              </div>
            </Grid>
            <Grid item xs={8} sm={4} md={2} >
              <div className="card-big-shadow" >
                <Card
                  className="card-just-text"
                  data-background="color"
                  data-color="orange"
                  data-radius="none"
                >
                  <CardBody style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                    <CardTitle tag="h4">
                      {t('about.about_page_val_3_title')}
                    </CardTitle>

                    <p className="card-description">
                      <strong>
                        {t('about.about_page_val_3_desc')}
                      </strong>
                    </p>

                  </CardBody>
                </Card>
                {/* end card */}
              </div>
            </Grid>
            <Grid item xs={8} sm={4} md={2} >
              <div className="card-big-shadow" >
                <Card
                  className="card-just-text"
                  data-background="color"
                  data-color="yellow"
                  data-radius="none"
                >
                  <CardBody style={{ paddingLeft: "10px", paddingRight: "10px" }} >
                    <CardTitle tag="h4">
                      {t('about.about_page_val_4_title')}
                    </CardTitle>

                    <p className="card-description">
                      <strong>
                        {t('about.about_page_val_4_desc')}
                      </strong>
                    </p>

                  </CardBody>
                </Card>
                {/* end card */}
              </div>
            </Grid>
            {/*<Grid item xs={8} sm={4} md={3} >
                  <div className="card-big-shadow" >
                    <Card
                      className="card-just-text"
                      data-background="color"
                      data-color="orange"
                      data-radius="none"
                      style={{backgroundImage: "linear-gradient(to left, #420075, #23005A, blue, green, #CDCD00, #CD7300, red) "}}
                    >
                      <CardBody style={{paddingLeft: "10px", paddingRight: "10px"}}>
                        <CardTitle tag="h4">
                          {t('about.about_page_val_5_title')}
                        </CardTitle>
                        
                        <p className="card-description">
                          <strong>
                          {t('about.about_page_val_5_desc')}
                          </strong>
                        </p>
                      
                      </CardBody>
                    </Card>
                    {/* end card 
                  </div>
                </Grid>*/}
          </Grid>
        </div>
        <SectionCertifications />
      </div>
      <SectionPartners />
      <SectionCTA2 />
      <SectionContactUs />
      <FooterBlack />
      <nav id="cd-vertical-nav">
        <ul>
          <li>
            <a
              data-number="1"
              href="#about"
              onClick={(e) => {
                e.preventDefault();
                document.getElementById("about").scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                  inline: "nearest",
                });
              }}
            >
              <span className="cd-dot" />
              <span className="cd-label">{t('about.about_us_title')}</span>
            </a>
          </li>
          <li>
            <a
              data-number="2"
              href="#values"
              onClick={(e) => {
                e.preventDefault();
                document.getElementById("values").scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                  inline: "nearest",
                });
              }}
            >
              <span className="cd-dot" />
              <span className="cd-label">{t('about.about_page_title_val')}</span>
            </a>
          </li>
          <li>
            <a
              data-number="3"
              href="#certifications"
              onClick={(e) => {
                e.preventDefault();
                document.getElementById("certifications").scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                  inline: "nearest",
                });
              }}
            >
              <span className="cd-dot" />
              <span className="cd-label">{t('about.certifications_title')}</span>
            </a>
          </li>
          <li>
            <a
              data-number="4"
              href="#partners"
              onClick={(e) => {
                e.preventDefault();
                document.getElementById("partners").scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                  inline: "nearest",
                });
              }}
            >
              <span className="cd-dot" />
              <span className="cd-label">Partenaires</span>
            </a>
          </li>
          <li>
            <a
              data-number="5"
              href="#consultation"
              onClick={(e) => {
                e.preventDefault();
                document.getElementById("consultation").scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                  inline: "nearest",
                });
              }}
            >
              <span className="cd-dot" />
              <span className="cd-label">{t('about.about_free_consultation')}</span>
            </a>
          </li>
          <li>
            <a
              data-number="6"
              href="#contact-us"
              onClick={(e) => {
                e.preventDefault();
                document.getElementById("contact-us").scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                  inline: "nearest",
                });
              }}
            >
              <span className="cd-dot" />
              <span className="cd-label">{t('about.about_contact_us')}</span>
            </a>
          </li>
        </ul>
      </nav>
    </Suspense>
  );
}

export default AboutUs;
