import React from "react";
//import Slider from "nouislider";

// reactstrap components
import { Container, Row, Col } from "reactstrap";
import {
  Card,
  CardHeader,
  CardBody,
  Collapse,
} from "reactstrap";

//import { Link } from 'react-router-dom'

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";
import AboutUsHeader from "components/Headers/AboutUsHeader.js";
import FooterBlack from "components/Footers/FooterBlack.js";
import SectionContactUs from "views/sections-sections/SectionContactUs.js";
import { useTranslation, Trans } from 'react-i18next';
import { useLocation } from 'react-router-dom'


function ServicesDetails ()  {

  document.documentElement.classList.remove("nav-open");
  const { t, i18n } = useTranslation();
  const location = useLocation()
  
  

  const [auditService, setAuditService] = React.useState(false);
  const [complianceService, setComplianceService] = React.useState(false);
  const [solutionService, setSolutionService] = React.useState(false);
  const [pentestService, setPentestService] = React.useState(true);
  const [consultingService, setConsultingService] = React.useState(false);
  const [hash, setHash] = React.useState('');
  


  

  React.useEffect(() => {

    if (location && location.state && location.state.service) {
      setAuditService(location.state.service === "audit");
      setComplianceService(location.state.service === "compliance");
      setSolutionService(location.state.service === "solution");
      setPentestService(location.state.service === "pentest");
      setConsultingService(location.state.service === "consulting");
      if(location.hash)
        setHash(location.hash)
      location.state= null
      setTimeout(() => {
        const element = document.getElementById('col');
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }

    if(!localStorage.getItem('lang'))
    localStorage.setItem('lang', "fr");
    else {
      i18n.changeLanguage(localStorage.getItem('lang'));
    }
    document.body.classList.add("blog-posts");
    if (!hash && hash === '' ) {
      document.body.scrollTop = 0;
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const element = document.getElementById('col');
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("blog-posts");
    };
  }, []);
  //document.documentElement.classList.add("nav-open");
  return (
    <>
      <ColorNavbar />
      <AboutUsHeader />
      <div className="section-space" />
      <div className="section">
        <Container id="details">
        <Row >
          <Col md="12" id="col">
              <CardBody className="text-center" style={{ fontSize: "16px", color: "#7f7f7f" }}>
                <h3 className="description">{t('services.services_title')}</h3><br />

                <p className="description">{t('services.services_title_desc')}<br />
                  <Trans
                    i18nKey='services.services_intro' 
                    components={{ 
                        1: <a href='#contact-us' />,
                        2: <strong />
                    }}> </Trans>
                  <br /> 
                </p>
              
              </CardBody>
            </Col>
            {/** position: "-webkit-sticky", position: "sticky", */}
            <Col md="4" style={{ bottom: "auto", top: "1", zIndex: 1}}>
              <h3 className="section-title">Services</h3>
              <Card className="card-refine">
                <div
                  aria-expanded={true}
                  aria-multiselectable={true}
                  className="panel-group"
                  id="accordion"
                >
                  <CardHeader
                    className="card-collapse"
                    id="pentest"
                    role="tab"
                  >

                    <h5 className="mb-0 panel-title" {...(pentestService && {
                      style: { backgroundColor: "#51cbce" }
                      })}>
                      <a
                        aria-expanded={pentestService}
                        href="#col"
                        onClick={(e) => {
                          setHash("details");
                          setPentestService(true);
                          setAuditService(false);
                          setComplianceService(false);
                          setSolutionService(false);
                          setConsultingService(false);
                          
                        }}
                      >
                        {t('service_pentest.pentest_title')} <i className="nc-icon nc-minimal-down" />
                      </a>
                    </h5>
                  </CardHeader>
                  <CardHeader
                    className="card-collapse"
                    id="audit"
                    role="tab"
                  >
                    <h5 className="mb-0 panel-title" {...(auditService && {
                      style: { backgroundColor: "#51cbce" }
                    })}>
                      <a
                        aria-expanded={auditService}
                        href="#col"
                        onClick={(e) => {
                          setHash("details") ;
                          setAuditService(!auditService);
                          setPentestService(auditService ? true : false);
                          setComplianceService(false);
                          setSolutionService(false);
                          setConsultingService(false);
                        }}
                      >
                         {t('service_audit.audit_title')} <i className="nc-icon nc-minimal-down" />
                      </a>
                    </h5>
                  </CardHeader>
                  <CardHeader
                    className="card-collapse"
                    id="compliance"
                    role="tab"
                  >
                    <h5 className="mb-0 panel-title" {...(complianceService && {
                      style: { backgroundColor: "#51cbce" }
                    })}>
                      <a
                        aria-expanded={complianceService}
                        href="#col"
                        onClick={(e) => {
                          setComplianceService(!complianceService);
                          setPentestService(complianceService ? true : false);
                          setAuditService(false);
                          setSolutionService(false);
                          setConsultingService(false);
                          setHash("details") ;
                        }}

                      >
                        {t('service_compliance.compliance_title')} <i className="nc-icon nc-minimal-down" />
                      </a>
                    </h5>
                  </CardHeader>
                  <CardHeader
                    className="card-collapse"
                    id="solution"
                    role="tab"
                  >
                    <h5 className="mb-0 panel-title" {...(solutionService && {
                      style: { backgroundColor: "#51cbce" }
                    })}>
                      <a
                        aria-expanded={solutionService}
                        href="#col"
                        onClick={(e) => {
                          setComplianceService(false);
                          setPentestService(solutionService ? true : false);
                          setAuditService(false);
                          setSolutionService(!solutionService);
                          setConsultingService(false);
                          setHash("details") ;
                        }}

                      >
                         {t('service_solution.solution_title')}  <i className="nc-icon nc-minimal-down" />
                      </a>
                    </h5>
                  </CardHeader>
                  <CardHeader
                    className="card-collapse"
                    id="solution"
                    role="tab"
                  >
                    <h5 className="mb-0 panel-title" {...(consultingService && {
                      style: { backgroundColor: "#51cbce" }
                    })}>
                      <a
                        aria-expanded={consultingService}
                        href="#col"
                        onClick={(e) => {
                          setComplianceService(false);
                          setPentestService(consultingService ? true : false);
                          setAuditService(false);
                          setSolutionService(false);
                          setConsultingService(!consultingService)
                          setHash("details") ;
                        }}

                      >
                        Consulting <i className="nc-icon nc-minimal-down" />
                      </a>
                    </h5>
                  </CardHeader>

                </div>
              </Card>
              {/* end card */}
            </Col>
            <Col md="8">
              <div className="products " id="desc">
                <Collapse isOpen={pentestService}>
                  <CardBody style={{ fontSize: "16px" }}>
                    <Row className="row description" id="pentest-desc">
                      <div >
                        <div className=" mb-40">
                          <h2> {t('service_pentest.pentest_title')}</h2>
                          <br />
                            <p className="description"> {t('service_pentest.pentest_desc')}</p>

                        </div>
                      </div>
                    </Row>
                    <Row className="row description">
                      <div className="details">
                        <div className="article-content ">
                          <h3>{t('services_common.service_goals')}</h3>
                          <ul className="check-list description">
                            <li><p>{t('service_pentest.pentest_goal_p1')}</p></li>
                            <br />
                            <li><p>{t('service_pentest.pentest_goal_p2')}</p></li>
                            <br />
                            <li><p>{t('service_pentest.pentest_goal_p3')}</p></li>
                            <br />
                            <li><p>{t('service_pentest.pentest_goal_p4')}</p></li>
                          </ul>
                        </div>
                      </div>
                    </Row>
                    <Row className="description">
                      <h4><strong>{t('services_common.service_approach')}</strong></h4>
                        <p>{t('service_pentest.pentest_approach_intro')}</p>
                        
                      <ol className="gradient-list description space-top">
                        <li>
                          <strong>{t('service_pentest.pentest_approch_p1_title')} </strong><br />
                          <p>{t('service_pentest.pentest_approch_p1_desc')}</p>
                          <br />
                        </li>
                        <li>
                          <strong>{t('service_pentest.pentest_approch_p2_title')} </strong><br />
                          <p>{t('service_pentest.pentest_approch_p2_desc')}</p>
                          <br />
                        </li>
                        <li>
                          <strong>{t('service_pentest.pentest_approch_p3_title')} </strong><br />
                          <p>{t('service_pentest.pentest_approch_p3_desc')}</p>
                          <br />
                        </li>
                        <li>
                          <strong>{t('service_pentest.pentest_approch_p4_title')} </strong><br />
                          <p>{t('service_pentest.pentest_approch_p4_desc')}</p>
                        </li>
                      </ol>
                    </Row>
                  </CardBody>
                </Collapse>
                <Collapse isOpen={auditService} style={{ fontSize: "16px", color: "#7f7f7f" }}>
                  <CardBody style={{ fontSize: "16px" }}>
                    <Row className="row description" id="audit-desc">

                        <div className="mb-40 ">
                          <h2> {t('service_audit.audit_title')}</h2>
                          <br />
                          {t('service_audit.audit_goal_desc')}
                        </div>

                    </Row>
                    <Row className="row description">
                      <div className="details">
                        <div className="article-content ">
                          <h3>{t('services_common.service_goals')}</h3>
                          <ul className="check-list">
                            <li>{t('service_audit.audit_goal_p1')}</li><br />
                            <li>{t('service_audit.audit_goal_p2')}</li><br />
                            <li>{t('service_audit.audit_goal_p3')}</li><br />
                            <li>{t('service_audit.audit_goal_p4')}</li><br/>
                          </ul>
                        </div>
                      </div>
                    </Row>
                    <Row className="row description">
                      <h3><span>{t('service_audit.audit_approach_title')}</span></h3><br />
                      <ol className="gradient-list article-content space-top">
                        <li> <strong>{t('service_audit.audit_approch_p1_title')} </strong><br />
                          {t('service_audit.audit_approch_p1_desc')}
                        </li>
                          <br />
                          <li> <strong>{t('service_audit.audit_approch_p2_title')} </strong><br />
                          {t('service_audit.audit_approch_p2_desc')}
                        </li>
                        <br />
                        <li> <strong>{t('service_audit.audit_approch_p3_title')} </strong><br />
                          {t('service_audit.audit_approch_p3_desc')}
                        </li>
                          <br />
                          <li> <strong>{t('service_audit.audit_approch_p4_title')} </strong><br />
                          {t('service_audit.audit_approch_p4_desc')}
                        </li>
                        <br />
                        <li> <strong>{t('service_audit.audit_approch_p5_title')} </strong><br />
                          {t('service_audit.audit_approch_p5_desc')}
                        </li>
                      </ol>
                    </Row>

                  </CardBody>
                </Collapse>

                <Collapse isOpen={complianceService}>
                  <CardBody style={{ fontSize: "16px" }}>
                  <Row className="row description" id="compliance-desc">
                        <div className="mb-40">
                          <h2>{t('service_compliance.compliance_title')}</h2><br />
                          {t('service_compliance.compliance_goal_desc')}
                        </div>
               
                    </Row>
                    <Row className="row description">
                      <div className="details">
                        <div className="article-content ">
                          <h3>{t('service_compliance.compliance_approach_title')}</h3>
                          <br />
                          <ul>
                            <li>
                              <a href="/cyber-safe" className="description"><strong>{t('service_compliance.compliance_cybersafe')}</strong></a><br />
                              {t('service_compliance.compliance_cybersafe_p1')}
                            </li>
                            <br />
                            <li>
                              <strong>{t('service_compliance.compliance_cis')}</strong><br />
                              {t('service_compliance.compliance_cis_desc')}
                            </li>
                            <br />
                            <li>
                              <strong>{t('service_compliance.compliance_nist')}</strong><br />
                              {t('service_compliance.compliance_nist_desc')}
                              <ul className="check-list">
                                <li> {t('service_compliance.compliance_nist_p1')}</li>
                                <li> {t('service_compliance.compliance_nist_p2')}</li>
                                <li> {t('service_compliance.compliance_nist_p3')}</li>
                                <li> {t('service_compliance.compliance_nist_p4')}</li>                              
                              </ul>
                            </li>
                            <br />
                           
                          </ul>
                        </div>
                      </div>

                    </Row>
                  </CardBody>
                </Collapse>
                <Collapse isOpen={solutionService}>
                  <CardBody style={{ fontSize: "16px" }}>
                    <Row className="row description" id="solution-desc">

                        <div className="mb-40">
                          <h2>{t('service_solution.solution_title')}</h2><br />
                            <p>{t('service_solution.solution_goal_desc')}</p>
                        </div>
                    </Row>
                    <Row className="row description">
                      <div className="details">
                        <div className="article-content ">
                          <h3>{t('service_solution.solutions_sub_title_1')}</h3>
                          <ul>
                            <li>
                              <strong>{t('service_solution.solution_p1')}</strong><br />
                              {t('service_solution.solution_p1_desc')}
                            </li>
                            <br />
                            <li>
                              <strong>{t('service_solution.solution_p2')}</strong><br />
                              {t('service_solution.solution_p2_desc')}
                            </li>
                            <br />
                            <li>
                              <strong>{t('service_solution.solution_p3')}</strong><br />
                              {t('service_solution.solution_p3_desc')}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Row>
                    <div className="row description">
                      <h3>{t('service_solution.solutions_others')}</h3>
                      {t('service_solution.solutions_others_desc')}
                    </div>
                  </CardBody>
                </Collapse>
                <Collapse isOpen={consultingService}>
                  <CardBody style={{ fontSize: "16px" }}>
                    <Row className="row description" id="consulting-desc">
                      <div >
                        <div className=" mb-40">
                          <h2> {t('service_consulting.consulting_title')}</h2>
                          <br />
                            <p className="description"> {t('service_consulting.consulting_goal_desc')}</p>

                        </div>
                      </div>
                    </Row>
              
                    <Row className="description">
                      <h4><strong>{t('service_consulting.consulting_approach_title')}</strong></h4>
                       
                        
                      <ul className="gradient-list description space-top">
                        <li>
                          <strong>{t('service_consulting.consulting_investigations')} </strong><br />
                          <p>{t('service_consulting.consulting_investigations_desc')}</p>
                          <br />
                        </li>
                        <li>
                          <strong>{t('service_consulting.consulting_architecture')} </strong><br />
                          <p>{t('service_consulting.consulting_architecture_desc')}</p>
                          <br />
                        </li>
                        <li>
                          <strong>{t('service_consulting.consulting_risk_analysis')} </strong><br />
                          <p>{t('service_consulting.consulting_risk_analysis_desc')}</p>
                          <br />
                        </li>
                        <li>
                          <strong>{t('service_consulting.consulting_awareness')} </strong><br />
                          <p>{t('service_consulting.consulting_awareness_desc')}</p>
                          <br />
                        </li>
                        
                      </ul>
                      <h4><strong>{t('service_consulting.consulting_more')}</strong></h4>
                      <p>{t('service_consulting.consulting_more_desc')}</p>
                      <br />
                    </Row>
                  </CardBody>
                </Collapse>
              </div>
            </Col>
          </Row>
        </Container>
        
      </div>
      <SectionContactUs />
      <FooterBlack />
    </>
  );
}

export default ServicesDetails;
