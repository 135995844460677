import React from "react";

// reactstrap components
import {
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";


import { useTranslation } from 'react-i18next';

function SectionCertifications() {
  
    const { t, i18n } = useTranslation();
  
    return (
        <div className="section section-dark" id="certifications">
            <Row>
            <Container>
                <Row>
                    <Col className="ml-auto mr-auto text-center" md="8">
                    <h2 className="title">{t('about.certifications_title')}</h2>
                    
                    </Col>
                </Row>
                <Row>
                    <Col className="ml-auto mr-auto text-center" md="8" >
                    <Card className="card-blog card-plain">
                        <div className="card-avatar">
                            <img
                            alt="OSCE-Certification"
                            src={require("assets/img/osce_2.png") }
                            style={{ width: "80%", maxWidth: "150px", height: "auto" }}
                            />
                        </div>
                        <CardBody>
                        <div className="author">
                            <CardTitle tag="h4"> {t('about.certification_title')}</CardTitle>
                        </div>
                        <p className="description">
                            {t('about.certification_desc')}
                        </p>
                        </CardBody>
                    </Card>
                    </Col>
                </Row>
            </Container>
            </Row>
        </div>
    )
}
export default SectionCertifications;