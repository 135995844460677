
import React from "react";

// reactstrap components
import {
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";

import { useTranslation } from 'react-i18next';

// core components

function SectionAudit() {
  const { t } = useTranslation();
  return (
    <>
        <div className="section secion cd-section" id="audit">
            <div className="blog-1" id="blog-1">
                <Container>
                <Row>
                    <Col className="ml-auto mr-auto" md="8">
                    <h2 className="title">{t('services.services_audit')} & {t('services.services_consulting')}</h2>
                    <br />
                    <Card className="card-plain card-blog">
                        <Row>
                        <Col md="5">
                            <div className="card-image">
                            <img
                                alt="..."
                                className="img"
                                src={
                                    require("assets/img/webp/compliance.webp")                               
                                }
                            />
                            </div>
                        </Col>
                        <Col md="6">
                            <CardBody>
                            <CardTitle >
                                <h6 className="card-category text-info">
                                    {t('services.services_compliance')}
                                </h6>
                            </CardTitle>
                            <p className="card-description">
                                {t('services.services_compliance_resume')}
                            </p>
                            </CardBody>
                        </Col>
                        </Row>
                        <div className="ml-auto mr-auto buttons text-center space-top" md="10" >
                            <Button
                                tag={Link}
                                className="btn-round btn-center "
                                color="danger"
                                to={{
                                pathname:"/services/details",
                                hash: "#audit",
                                state: {
                                    service: "audit", 
                                }
                                }}
                            >
                            
                                {t('banner.banner_button_1')}
                            </Button>
                        </div>
                    </Card>
                    <Card className="card-plain card-blog">
                        <Row>
                        <Col md="6">
                            <CardBody>
                            {/*<h6 className="card-category text-danger">
                                <i className="fa fa-free-code-camp mr-1" />
                                Trending
                            </h6>*/}
                            <CardTitle>
                                <h6 className="card-category text-info">
                                    {t('services.services_consulting')}
                                </h6>
                            </CardTitle>
                            <p className="card-description">
                                {t('services.services_consulting_resume')}
                            </p>
                            </CardBody>
                        </Col>
                        <Col md="5">
                            <div className="card-image">
                            <img
                                alt="..."
                                className="img"
                                src={
                                require("assets/img/webp/conseil.webp")

                                }
                            />
                            </div>
                        </Col>
                        </Row>
                        <div className="ml-auto mr-auto buttons text-center space-top" md="10" >
                            <Button
                                tag={Link}
                                className="btn-round btn-center"
                                color="danger"
                                to={{
                                pathname:"/services/details",
                                hash: "#consulting",
                                state: {
                                    service: "consulting", 
                                }
                                }}
                            >
                            
                                {t('banner.banner_button_1')}
                            </Button>
                        </div>
                    </Card>
                    </Col>
                </Row>
                
                </Container>
            </div>
        </div>
    </>
  );
}

export default SectionAudit;
