import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";

// Formspree
import { useForm, ValidationError } from "@formspree/react";
//import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useTranslation, Trans } from 'react-i18next';

// core components

function SectionContactUs() {
  //Formspree return 
  
  const [notValidEmail, setNotValidEmail] = React.useState(false)

  //const { executeRecaptcha } = useGoogleReCaptcha();

  const [state, handleSubmit] = useForm("mrgrealj")

  const { t } = useTranslation();

  const isValidEmail = /^[\w-.]+@([\w-]+.)+[\w-]{2,4}$/g;

  const validateEmail = (e) => {
    if(e.target?.value && e.target.value.match(isValidEmail)){
      setNotValidEmail(false);
    }else{
      setNotValidEmail(true);
    }
  }

  if (state.succeeded) {
    return (
      <div className="section section-gray section-contactus  section-image cd-section" id="contact-us" style={{
        backgroundImage:
          "url(" +
          require("assets/img/webp/challenge.webp") +
          ")",
      }}>
        {/* ********* CONTACT US 1 ********* */}
        <div
          className="contactus-1 "
        >
          <Container >
            <Row>
              <Col className="ml-auto mr-auto" md="10">
                <Card className="card-contact no-transition">
                  <CardTitle className="text-center" tag="h3">
                    {t('contact.contact_title')}
                  </CardTitle>
                  <Row>
                    <Col className="ml-auto" md="5">
                      <CardBody>
                      <div className="info info-horizontal">
                          <div className="icon icon-info">
                            <i className="nc-icon nc-email-85" />
                          </div>
                          <div className="description">
                            <h4  className="info-title">
                              Email
                            </h4>
                              <p >
                              info@ictrust.ch<br />
                              {t('contact.pgp_msg')}
                              </p>
                            
                          </div>
                        </div>
                        <div className="info info-horizontal">
                          <div className="icon icon-danger">
                            <i className="nc-icon nc-badge" />
                          </div>
                          <div className="description">
                            <h4 className="info-title">Nous appeler</h4>
                            <p>
                              +41 76 787 10 07<br />
                              Lun - Ven 8h30-18h30
                            </p>
                          </div>
                        </div>
                      </CardBody>
                    </Col>
                    <Col className="mr-auto ml-auto text-center" md="5" style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                        <CardBody>
                          <Row>
                          <div className="description text-center" >
                            <h5 className="info-title">Votre message a bien été envoyé</h5>
                          </div>
                          </Row>
                        </CardBody>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Container>
          </div>
          </div>
        )
  }

  return (
    <>
        {/* ********* CONTACT US 1 ********* */}
        <div className=" section-gray section-contactus cd-section" id="contact-us">
        {/* ********* CONTACT US 1 ********* */}
        <div
          className="contactus-1 section-image"
          style={{
            backgroundImage:
              "url(" +
              require("assets/img/webp/challenge.webp") +
              ")",
          }}
        >
          <Container >
            <Row>
              <Col className="ml-auto mr-auto" md="10">
                <Card className="card-contact no-transition">
                  <CardTitle className="text-center" tag="h3">
                  {t('contact.contact_title')}
                  </CardTitle>
                  <Row>
                    <Col className="ml-auto" md="5">
                      <CardBody>
                        <div className="info info-horizontal">
                          <div className="icon icon-info">
                            <i className="nc-icon nc-email-85" />
                          </div>
                          <div className="description" >
                            <h4 className="info-title">
                              Email
                            </h4>
                            <div style={{ color: "black  !important"}}>
                              <p>
                              info@ictrust.ch<br />
                                
                               {t('contact.pgp_msg')}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="info info-horizontal">
                          <div className="icon icon-danger">
                            <i className="nc-icon nc-badge" />
                          </div>
                          <div className="description" style={{ color: "black  !important"}}>
                            <h4 className="info-title"> {t('contact.contact_phone')}</h4>
                            <p>
                              +41 76 787 10 07<br />
                              <Trans 
                                i18nKey='contact.opening' 
                                components={{ 
                                    1: <br />,
                                }}> </Trans>
                            </p>
                          </div>
                        </div>
                      </CardBody>
                    </Col>
                    
                    <Col className="mr-auto" md="5">
                      <Form id="contact-form" method="post" role="form" onSubmit={handleSubmit}>
                        <CardBody>
                          <Row>
                            <Col md="6">
                              <FormGroup className="label-floating">
                                <label className="control-label">
                                {t('contact.contact_form_first_name')}
                                </label>
                                <Input
                                  name="name"
                                  placeholder={t('contact.contact_form_first_name')}
                                  type="text"
                                />
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <FormGroup className="label-floating">
                                <label className="control-label">
                                  {t('contact.contact_form_last_name')}
                                </label>
                                <Input
                                  name="name"
                                  placeholder={t('contact.contact_form_last_name')}
                                  type="text"
                                />
                                
                              </FormGroup>
                            </Col>
                          </Row>
                          <FormGroup className="label-floating" onChange={validateEmail}>
                            <label className="control-label">
                              Email
                            </label>
                            <Input
                              name="email"
                              placeholder="Email"
                              type="email"
                              errors={state.errors}
                            />
                            <ValidationError 
                            prefix="Email" 
                            field="email"
                            errors={state.errors}
                          />
                            {notValidEmail && <div className="ct-red"><label className="">{t('contact.contact_form_email_error')}</label></div>}
                          </FormGroup>
                          <FormGroup className="label-floating">
                            <label className="control-label">
                              Message
                            </label>
                            <Input
                              id="message"
                              name="message"
                              placeholder={t('contact.contact_form_message_content')}
                              type="textarea"
                              rows="6"
                              errors={state.errors}
                            />
                            <ValidationError 
                                prefix="Message" 
                                field="message"
                                errors={state.errors}
                            />
                          </FormGroup>
                          <Row>
                            <Col md="6">
                                 <input type="hidden" id="g-recaptcha-response" name="g-recaptcha-response"/>
                                <div className="g-recaptcha" data-sitekey="6Lch3mchAAAAALDwSiKtIugM2-amTQTSj-V6kdRj" data-size="compact" data-theme="dark" style={{ transform: "scale(0.77)", transformOrigin: "0 0" }}></div>
       
                            </Col>
                            <Col md="6">
                              <Button 
                                className="pull-right"
                                color="primary"
                                type="submit"
                                disabled={state.submitting}
                              >
                                {t('contact.contact_form_send')}
                              </Button>
                            </Col>
                            <Col md="6">
                              <input type="text" name="_gotcha" style={{display:"none"}} />
                            </Col>
                          </Row>
                        </CardBody>
                      </Form>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Container>
          </div>
        </div>
        {/* ********* END CONTACT US 1 ********* */}
    </>
  );
}

export default SectionContactUs;
