import React from "react";

// reactstrap components
import { Container, Button } from "reactstrap";
import { useTranslation } from "react-i18next"

// core components

function AboutUsHeader() {
  const { t } = useTranslation()
  return (
    <>
      <div
        className="page-header page-header-small"
        style={{
          backgroundImage:
            "url(" +
            require("assets/img/webp/banner_2.webp") +
            ")",
        }}
      >
        <div className="filter filter-black" />
        <div className="content-center">
          <Container>
            <h1>
              {t('banner.banner_generic_title')}<br />
            </h1>
            <h5>{t('banner.banner_generic_subtitle')}</h5>
            {/*<h3>Êtes-vous prêt pour un service "out-of-the box"? <br /> </h3>*/}
            <h3>{t('banner.banner_generic_desc')}</h3>
            <div style={{marginTop: "20px"}}>

                <Button
                      className="btn-round"
                      color="danger"
                      onClick={(e) => {
                        e.preventDefault();
                        document.getElementById("contact-us").scrollIntoView({
                          behavior: "smooth",
                          block: "start",
                          inline: "nearest",
                        });
                      }}
                    >
                    {t('banner.banner_generic_button')}
                    </Button>

            </div>         
          </Container>
        </div>
      </div>
    </>
  );
}

export default AboutUsHeader;
