import React from "react";


import { MdWeb } from "@react-icons/all-files/md/MdWeb";
import { FaNetworkWired } from "@react-icons/all-files/fa/FaNetworkWired";
import { BiNetworkChart } from "@react-icons/all-files/bi/BiNetworkChart";
import { FaMobileAlt } from "@react-icons/all-files/fa/FaMobileAlt";
import { GiPoliceTarget } from "@react-icons/all-files/gi/GiPoliceTarget";
import { AiFillSecurityScan } from "@react-icons/all-files/ai/AiFillSecurityScan";
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
// reactstrap components
import {
  Card,
  CardBody,
  CardTitle,
  Badge,
  Container,
  Row,
  Col,
  Button,
} from "reactstrap";

// core components
function SectionPentest() {
  const [visible] = React.useState(false);
  const { t } = useTranslation();


  return (
    <>
      <div className="section section-gray cd-section" id="pentest">
          <Container style={{display: "flex", grid: "3"}} >
            <Row>
              <Col className="ml-auto mr-auto text-center" md="10">
                <h2 className="title">{t('services.services_pentest')}</h2>
                <p className="description">{t('services.services_pentest_desc')}</p>
                {/*<p>Un test d'intrusion, test de pénétration ou pentest est une méthode qui consiste à se mettre dans la peau d'un attaquant afin d'analyser le niveau de sécurité de votre organisation. </p>*/}
                <br />
                <Row>
                  <Col className="ml-auto mr-auto text-center" md="4" style={{height: "100%"}} >
                    <Card className="card-blog" >
                      <CardBody>
                        <div className="card-image">
                            <div className="icon icon-danger ">
                              <MdWeb size={50}/>
                            </div>
                        </div>
                        
                        <CardTitle>
                        <h6 className="card-category text-info">{t('services.services_pentest_web_title')}</h6>
                        </CardTitle>
                        <p className="card-description">
                        {t('services.services_pentest_web_desc')}
                        </p>
                        
                      </CardBody>
                    </Card>
                  </Col>
                  <Col className="ml-auto mr-auto text-center" md="4" style={{height: "100%"}}>
                    <Card className="card-blog" >
                      <CardBody>
                      <div className="card-image">
                        
                          <div className="icon icon-danger ">
                            <FaNetworkWired size={50}/>
                          </div>
                       
                      </div>
                        <CardTitle>
                        <h6 className="card-category text-info">{t('services.services_pentest_internal_title')}</h6>
                        </CardTitle>
                        <p className="card-description">
                        {t('services.services_pentest_internal_desc')} 
                        </p>
                        
                        
                      </CardBody>
                    </Card>
                  </Col>
                  <Col className="ml-auto mr-auto text-center" md="4" style={{height: "100%"}}>
                    <Card className="card-blog" >
                      <CardBody>
                      <div className="card-image" >
                       
                          <div className="icon icon-danger ">
                            <BiNetworkChart size={50}/>
                          </div>
                        
                      </div>
                        <CardTitle>
                        <h6 className="card-category text-info">{t('services.services_pentest_external_title')} </h6>
                        </CardTitle>
                        <p className="card-description" >
                          {t('services.services_pentest_external_desc')} <br />
                          
                        </p>

                      </CardBody>
                    </Card>
                  </Col>
                  <Col className="ml-auto mr-auto text-center" md="4" style={{height: "100%"}}>
                    <Card className="card-blog">
                      <CardBody>
                      <div className="card-image" >
                       
                          <div className="icon icon-danger ">
                            <FaMobileAlt size={50}/>
                          </div>
                        
                      </div>
                        <CardTitle>
                        <h6 className="card-category text-info">{t('services.services_pentest_mobile_title')} </h6>
                        </CardTitle>
                        <p className="card-description" >
                          {t('services.services_pentest_mobile_desc')} <br />
                         
                        </p>               
                      </CardBody>
                    </Card>
                  </Col>
                  <Col className="ml-auto mr-auto text-center" style={{height: "100%"}} md="4" mh="4">
                    <Card className="card-blog" >
                      <CardBody>
                      <div className="card-image" >
                        
                          <div className="icon icon-danger ">
                            <GiPoliceTarget size={50}/>
                          </div>
                        
                      </div>
                        <CardTitle>
                        <h6 className="card-category text-info">{t('services.service_pentest_social_engineering_title')} </h6>
                        </CardTitle>
                        <p className="card-description" >
                          {t('services.service_pentest_social_engineering_desc')} <br />
                          
                        </p>
                        
                      </CardBody>
                    </Card>
                  </Col>
                  <Col className="ml-auto mr-auto text-center" md="4" >
                    <Card className="card-blog" >
                      <CardBody>
                        <div className="card-image" >
                            <div className="icon icon-danger">
                              <AiFillSecurityScan size={50}/>
                            </div>
                        </div>
                        <CardTitle>
                          <h6 className="card-category text-info">{t('services.service_pentest_vuln_scan_title')} </h6>
                        </CardTitle>
                        <p className="card-description" >
                          {t('services.service_pentest_vuln_scan_desc')} <br />
                        </p>
                      
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
          <br />
              <div className="ml-auto mr-auto buttons text-center" md="10" >
                <Button
                    tag={Link}
                    className="btn-round btn-center"
                    color="danger"
                    to={{
                      pathname:"/services/details",
                      hash: "#pentest",
                      state: {
                        service: "pentest", 
                      }
                    }}
                >
                  {t('banner.banner_button_1')}
                </Button>
              </div>
          {
            visible &&
            <div className="wrapper">
            
              <div className="section section-white">
                <Container>
                  <Row>
                    <Col className="ml-auto mr-auto text-center title" md="8">
                      <h1>Sécuriser PostgreSQL - Linux</h1>
                      <Col className="ml-auto mr-auto" md="10">
                      <div className="text-center">
                        <Badge className="main-tag" color="info">
                          Technique
                        </Badge>
                        <h6 className="title-uppercase">14 juillet 2021</h6>
                      </div>
                    </Col>
                    </Col>
                  </Row>
                  <Row>
                    
                    <Col className="ml-auto mr-auto" md="8">
                    
                    </Col>
                  </Row>
                </Container>
                </div>
                </div>
          }


        </div>
        {/* ********* END BLOGS 2 ********* */}
    </>
  );
}
export default SectionPentest;
