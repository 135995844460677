import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from 'assets/locales/en';
import fr from 'assets/locales/fr';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: en,
      },
      fr: {
        translation: fr,
      },
    },
    fallbackLng: 'fr',
    interpolation: {
      escapeValue: false,
    },
  });

  
  if(!localStorage.getItem('lang'))
    localStorage.setItem('lang', "fr");
  else {
    i18n.changeLanguage(localStorage.getItem('lang'));
  }
export default i18n;