import React from "react";
import { Link } from "react-router-dom";
// nodejs library that concatenates strings
import classnames from "classnames";
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";

import Logo from "assets/img/logo_white.png"
import Logo2 from "assets/img/logo_2.png"
// reactstrap components
import {
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavLink,
  NavItem,
  Nav,
  Container,
  UncontrolledTooltip,
} from "reactstrap";
import { useTranslation } from 'react-i18next';

// core components

const lngs = {
  en: { nativeName: 'English' },
  fr: { nativeName: 'Français' }
};



function ColorNavbar() {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [bodyClick, setBodyClick] = React.useState(false);
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [logo, setLogo] = React.useState(Logo)
  


  const { t, i18n } = useTranslation();
  
  if(!localStorage.getItem('lang'))
    localStorage.setItem('lang', "fr")
    


  document.documentElement.lang = localStorage.getItem('lang');

  const [count, setCounter] = React.useState(0);
  const [ show, setShow ] = React.useState(false);

  React.useEffect(() => {
    let headroom = new Headroom(document.getElementById("navbar-main"));
    // initialise
    headroom.init();
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 180 ||
        document.body.scrollTop > 180
      ) {
        setNavbarColor("");
        setLogo(Logo2)
      } else if (
        document.documentElement.scrollTop < 180 ||
        document.body.scrollTop < 180
      ) {
        setNavbarColor("navbar-transparent");
        setLogo(Logo)
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <>
      {bodyClick ? (
        <div
        
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setBodyClick(false);
            setCollapseOpen(false);
            setShow(false)
          }}
        />
      ) : null}
      <Navbar
        className={classnames("fixed-top", navbarColor)}
        style={{maxHeight: "78px", maxWidth: "100vw"}}
        expand="lg"
        id="navbar-main"
        
      >
        <Container md="12">
          <div className="navbar-translate">
            <NavbarBrand id="navbar-brand" href="/index">
              <img src={logo} alt="ICTrust" height="45px" width="150px" style={{ marginBottom: "-2px", paddingTop:"-10px", display: "block", flexWrap: "wrap"}} />
            </NavbarBrand>
            <UncontrolledTooltip placement="bottom" target="navbar-brand">
              ICTrust
            </UncontrolledTooltip>
            <button
              className="navbar-toggler"
              id="navigation"
              type="button"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setBodyClick(true);
                setCollapseOpen(true);
              }}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <Collapse navbar isOpen={collapseOpen}>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <NavLink href="/index">{t('navbar.nav_home')}</NavLink>
              </NavItem>
             
              <UncontrolledDropdown  id="servicesMenu" nav inNavbar  >
                <DropdownToggle  className="mr-2" color="default" caret nav>
                {t('navbar.nav_services')}
                </DropdownToggle>
                
                <DropdownMenu className=" ml-6 dropdown-danger" right >
                                  {/*<DropdownItem to="/services#pentest" tag={Link}>
                                    {t('navbar.nav_service_1')}
                                  </DropdownItem>
                                  <DropdownItem to="/services#audit" tag={Link}>
                                  {t('navbar.nav_service_2')}
                                  </DropdownItem>
                                  <DropdownItem
                                    to="/services#solutions"
                                    tag={Link}
                                  >
                                    {t('navbar.nav_service_3')}
                                  </DropdownItem>*/}
                  <DropdownItem to="/services" tag={Link} aria-label="ICTrust cybersecurity services" >
                    {t('navbar.nav_services')}
                  </DropdownItem>
                  <DropdownItem to="/services/details" tag={Link}  aria-label="ICTrust cybersecurity services details">
                    {t('navbar.nav_service_details')}
                  </DropdownItem>
                  {/*
                  <UncontrolledDropdown  >
                    <DropdownToggle
                      className="dropdown-item"
                      color="default"
                      tag="a"
                      caret
                      onClick={() => setCollapseOpen(true)}
                    >
                      {t('navbar.nav_service_details')}
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-danger">
                      <DropdownItem
                        to={{
                          pathname: "/services/details",
                          hash: "#pentest",
                          state: {
                            service: "pentest", // save the current location and pass with route push
                          }
                        }}
                        tag={Link}
                        >
                        {t('navbar.nav_service_1')}
                      </DropdownItem>
                      <DropdownItem
                        to={{
                          pathname: "/services/details",
                          hash: "#audit",
                          state: {
                            service: "audit", // save the current location and pass with route push
                          }
                        }}
                        tag={Link}
                      >
                        {t('navbar.nav_service_2')}
                      </DropdownItem>
                      <DropdownItem
                        to={{
                          pathname: "/services/details",
                          hash: "#solution",
                          state: {
                            service: "solution", // save the current location and pass with route push
                          }
                        }}
                        tag={Link}
                      >
                        {t('navbar.nav_service_3')}
                      </DropdownItem>
                      <DropdownItem
                        to={{
                          pathname: "/services/details",
                          state: {
                            service: "solution", // save the current location and pass with route push
                          }
                        }}
                        tag={Link}
                      >
                        {t('navbar.nav_service_4')}
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>*/}
                  </DropdownMenu>
                </UncontrolledDropdown>
                
              {/*<UncontrolledDropdown nav inNavbar>
              <DropdownToggle className="mr-2" color="default" caret nav>
                {t('navbar.nav_services')}
                </DropdownToggle>
                <DropdownMenu className=" ml-6 dropdown-danger" right>
                  <DropdownItem
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    Action
                  </DropdownItem>
                  <DropdownItem
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    Another action
                  </DropdownItem>
                  <UncontrolledDropdown>
                    <DropdownToggle
                      className="dropdown-item"
                      color="default"
                      tag="a"
                      caret
                    >
                      Submenu
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-danger">
                      <DropdownItem
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        Submenu action
                      </DropdownItem>
                      <DropdownItem
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        Submenu action
                      </DropdownItem>
                      <UncontrolledDropdown>
                        <DropdownToggle
                          className="dropdown-item"
                          color="default"
                          tag="a"
                          caret
                        >
                          Subsubmenu
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-danger">
                          <DropdownItem
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            Subsubmenu action 1
                          </DropdownItem>
                          <DropdownItem
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            Subsubmenu action 2
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                      <UncontrolledDropdown>
                        <DropdownToggle
                          className="dropdown-item"
                          color="default"
                          tag="a"
                          caret
                        >
                          Second Subsubmenu
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-danger">
                          <DropdownItem
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            Subsubmenu action 1
                          </DropdownItem>
                          <DropdownItem
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            Subsubmenu action 2
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </DropdownMenu>
            </UncontrolledDropdown>*/}
             {
              localStorage.getItem('lang') === "fr" && <NavItem>
              <NavLink href="https://fr.blog.ictrust.ch">{t('navbar.nav_blog')}</NavLink>
                </NavItem>
             }
             {/*} <NavItem>
                <NavLink href="/blog">{t('navbar.nav_blog')}</NavLink>
          </NavItem>*/}
              <NavItem>
                <NavLink href="/about-us">{t('navbar.nav_about_us')}</NavLink>
              </NavItem>
              
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle className="mr-2" color="default" caret nav>
                  {t('navbar.nav_lang')}
                </DropdownToggle>
                <DropdownMenu className="dropdown-danger" right>
                    {Object.keys(lngs).map((lng) => (
          
                        <DropdownItem key={lng} style={{ pointerEvents: i18n.resolvedLanguage === lng ? 'none' : 'all' }} className="btn-clear" type="submit" onClick={() => {
                        setCounter(count + 1); 
                        localStorage.setItem('lang', lng);
                        
                        var currentPath = window.location.pathname
                        var endStr = currentPath.substring(currentPath.lastIndexOf("-")  + 1).toString();
                        if(endStr && (endStr === 'fr' || endStr === 'en') && endStr!== lng){
                            window.location.pathname = currentPath.replace("-"+endStr, "-"+lng);
                        }
                        window.location.reload();
                      }}  >
                        {lngs[lng].nativeName}
                      </DropdownItem>
                 
                    ))}     
              
                </DropdownMenu>  
                
              </UncontrolledDropdown>
                 
                
              {/*<NavItem>
                <Button
                  className="btn-round"
                  color="danger"
                  href="https://www.creative-tim.com/product/paper-kit-pro-react?ref=pkpr-color-navbar"
                  target="_blank"
                >
                  <i className="nc-icon nc-cart-simple" /> Buy Now
                </Button>
              </NavItem>*/}
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default ColorNavbar;
