import React from "react";

// reactstrap components
import { Badge, Button, Container, Row, Col } from "reactstrap";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";
import CGHeader from "components/Headers/CGHeader.js";

import FooterBlack from "components/Footers/FooterBlack";

function Politique() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("blog-posts");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("blog-posts");
    };
  });
  return (
    <>
      <ColorNavbar />
      <CGHeader />
     
      <div className="wrapper">
        <div className="main">
          <div className="section section-white">
            <Container>
              
              <Row>
                <Col className="ml-auto mr-auto description" md="8">
                    <div className="article-content">
                    <h4>1. Contexte contractuel</h4><br />
                    <p>Les présentes conditions générales sont applicables aux relations contractuelles de ICTrust SARL, notamment aux différents contrats de mandat (art. 394 ss CO).</p>
                    <h4>2. Limitation de responsabilité</h4><br />
                    <p>En vertu de l’art. 398 CO et dans le cadre de ses mandats, ICTrust SARL est uniquement responsable de la bonne et fidèle exécution du projet confié. En outre, la responsabilité de ICTrust SARL est de manière générale limitée au dol et à la faute grave. De plus, les parties cocontractantes sont responsables du respect d’éventuelles règles entourant les possibilités de communication et concernant d’éventuelles restrictions ou interdictions. Enfin, ICTrust SARL n’assume aucune responsabilité pour les documents qui lui sont remis, ni en relation avec le matériel erroné ou incomplet qui lui serait remis.</p>
                    <h4>3. Pouvoir de substitution et recours à des tiers</h4><br />
                    <p>ICTrust SARL conserve la possibilité de confier l’exécution des mandats à des tiers (art. 398 al. 3 CO). De même, l’assistance de tiers pourra être requise pour un appui sur certaines prestations, au libre choix de ICTrust SARL et ce sans devoir avertir les parties.</p>
                    
                    <h4>4. Propriété intellectuelle – conservation exclusive des droits</h4><br />
                    <p>ICTrust SARL conserve l’exclusivité des droits de propriété intellectuelle sur tous les projets, conceptions, œuvres, visuels, graphismes, designs, codes sources et autres éléments réalisés ou fournis. Elle n’est pas tenue de fournir les fichiers et codes sources, ni de céder les droits d’auteur, sauf accord en ce sens.<br/>
                    ICTrust SARL conserve la possibilité d’utiliser les travaux réalisés comme références commerciales sur son site internet, ou sur tout autre support promotionnel, sauf annonce écrite transmise au plus tard lors de la signature du contrat. De même, une mention spécifique indiquant sa contribution pourra être incluse sur le support final remis.</p>
                    <h4>5. Confidentialité des échanges et informations</h4><br />
                    <p>Les parties s’engagent à conserver de manière strictement confidentielle l’ensemble des informations et documents les concernant.</p>
                    <h4>6. Droit applicable et for</h4><br />
                    <p>Les présentes conditions générales annulent et remplacent toute version antérieure, et sont soumises au droit suisse. Pour toutes les contestations qui en découlent, le for est fixé à Vevey.</p>
                    <br />
                    <p>Version des conditions générales établie à Vevey, le 22 novembre 2022</p>
                    </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
      <FooterBlack />
    </>
  );
}

export default Politique;
